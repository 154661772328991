.workers_header {
  margin-top: 15px;
  /*position: relative;
  z-index: 102;*/
  .card-body {
    padding: 0.25rem 0.5rem;
    background-color: $header-bg;
    height: 90px;
    .workers_header__card_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      h4 {
        display: inline-block;
      }
    }
    .show_more__hash {
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
      text-align: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
.workers__balance {
  h4 {
    color: $green;
  }
}
#scrollToTopButton {
  position: fixed; /* Фиксированное / липкое положение */
  bottom: 20px; /* Поместите кнопку в нижней части страницы */
  right: 30px; /* Разместить кнопку 30 пикселей от правого */
  z-index: 99; /* Убедитесь, что он не перекрывается */
  border: none; /* Удалить границы */
  outline: none; /* Удалить контур */
  background-color: #626ed4; /* Установка цвета фона */
  color: white; /* Цвет текста */
  cursor: pointer; /* Добавить указатель мыши при наведении */
  padding: 15px; /* Немного отступов */
  border-radius: 10px; /* Округленные углы */
  font-size: 18px; /* Увеличить размер шрифта */
}

#scrollToTopButton:hover {
  background-color: #555; /* Добавить темно-серый фон при наведении курсора */
}

.min-width-200 {
  min-width: 220px !important;
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}
/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}
/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}
/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}
/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^='up']::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^='up']::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^='up']::before,
[tooltip][flow^='up']::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* FLOW: LEFT */
[tooltip][flow^='left']::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%);
}
[tooltip][flow^='left']::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.5em, -50%);
}

/* FLOW: Down */
[tooltip][flow^='down']::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^='down']::after {
  top: calc(100% + 5px);
  bottom: auto;
}
[tooltip][flow^='down']::before,
[tooltip][flow^='down']::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}
@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}
/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^='up']:hover::before,
[tooltip][flow^='up']:hover::after,
[tooltip][flow^='down']:hover::before,
[tooltip][flow^='down']:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^='left']:hover::before,
[tooltip][flow^='left']:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

.worker_cards {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 130px;
}
.worker_card__info {
  display: none;
  position: absolute;
  bottom: 15px;
  left: auto;
  right: auto;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
  font-size: 0.7rem;
  width: fit-content;
  white-space: nowrap;
  text-align: center;
  z-index: 500;
  .worker_card__info_temp {
    display: inline-block;
  }
}
.worker_card {
  margin: 2px 2px;
  position: relative;
  display: flex;
  justify-content: center;
  span {
    display: block;
    width: 12px;
    height: 12px;
    background-color: $green;
    &:hover ~ .worker_card__info {
      display: block;
    }
  }
  .worker_card__info_temp {
    color: $green;
  }
}
.worker_card.card_issue {
  span {
    background-color: $yellow;
  }
  .worker_card__info_temp {
    color: $yellow;
  }
}
.worker_card.card_error {
  span {
    background-color: $red;
  }
  .worker_card__info_temp {
    color: $red;
  }
}
.worker_card.card__unknown {
  span {
    background-color: $white;
  }
}
.worker_card.card__problem {
  span {
    background-color: $blue;
    position: relative;
    &:after,
    &:before {
      position: absolute;
      width: 16px;
      height: 0;
      content: '';
      border-bottom: 1px solid #fff;
      pointer-events: none;
      transform: rotate(45deg);
      top: 6px;
      left: -2px;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.worker_card.card__no_mining {
  span {
    position: relative;
    &:after,
    &:before {
      position: absolute;
      width: 16px;
      height: 0;
      content: '';
      border-bottom: 1px solid #fff;
      pointer-events: none;
      transform: rotate(45deg);
      top: 6px;
      left: -2px;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.worker_error {
  background-color: rgba(255, 0, 0, 0.3);
}
.worker_offline {
  background-color: rgba(255, 255, 255, 0.3);
}
.worker_little_error {
  background-color: rgba(236, 120, 10, 0.3);
}

.card__not_detected {
  span {
    position: relative;
    background: transparent;
    border: 1px solid #fff;
  }
}

.workers_filters {
  margin: 0 0 20px;
}

.mobile-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  font-size: 20px;
  margin-bottom: 10px;
  .clearFilters {
    color: $red;
    margin-left: 5px;
    margin-right: 0 !important;
  }
  .active {
    i {
      color: $blue;
    }
  }
  & > i {
    margin-right: 20px;
  }
  & > .dropdown {
    margin-right: 20px;
    .dropdown-menu.show{
      margin-top: -10vw;
    }
    .dropdown-item.active{

      color: $gray-400;
      background-color: $gray-50;
      li{
        display: flex;
        justify-content: space-between;
        i{
          margin-left: 20px;
          font-size: 1.3em;
        }
      }

    }
  }
  i {
    cursor: pointer;
    &.active {
      color: $blue;
    }
  }
}

.workers_filters__elements {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .form_control_react_select__control {
    padding: 0 !important;
  }
  .form_control_react_select__indicators {
    margin: 0 !important;
  }
  .form_control_react_select__value-container--is-multi {
    max-height: 58px;
  }
  label {
    position: static;
    font-size: inherit;
    margin-bottom: 0;
    pointer-events: auto;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
.workers_filters__element {
  margin-top: 5px;
  text-align: left;
}

.worker_statuses {
  display: flex;
  align-items: center;
  justify-content: center;
  /*@media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: wrap;
  }*/
  span {
    margin: 4px 4px -2px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    cursor: pointer;
    border-radius: 3px;
    color: $gray-500;
    i {
      font-size: 0.9em;
    }
    @media (max-width: 767px) {
      margin: 5px;
    }
  }
  .rejected,
  .rig-errors {
    i {
      color: red;
    }
    small {
      position: absolute;
      top: -80%;
      font-size: 10px;
    }
  }
}

.workers_table {
  td {
    vertical-align: middle;
  }
  tr:hover {
    background-color: rgba(53, 49, 79, 0.33);
  }
  .worker_statuses {
    width: 80px;
  }
  td:first-child {
    width: 24px;
  }
  td:last-child {
    width: 140px;
    @media (max-width: 767px) {
      width: 25px;
    }
  }
}

.worker_information {
  h4 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 5px;
  }
}
.worker_info {
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.worker_parts {
  display: flex;
  flex-wrap: wrap;
  .worker_parts__part {
    font-size: 12px;
    width: 50%;
    background-color: #465773;
    padding: 15px 5px 3px 5px;
    border-radius: 3px;
    border: 1px solid $gray-500;
    position: relative;
    text-transform: lowercase;
    line-height: 1.2;
    transition: all 0.3s;
    label {
      position: absolute;
      top: 1px;
      left: 3px;
      font-size: 12px;
      pointer-events: none;
      text-transform: none;
      margin: 0;
    }
    &:hover {
      label {
        color: $white;
      }
      border: 1px solid $white;
    }
  }
  .workers_header__info_part_algo {
    max-height: 105px;
    overflow-y: auto;
  }
}

.worker_charts {
  .actions__items {
    flex-direction: column;
  }
  margin-bottom: 15px;
}
.worker_chart_parent {
  height: 150px;
  position: relative;
  @media (max-width: 768px) {
    &:first-child {
      height: 200px;
    }
  }
}

.rig_full_info {
  table {
    width: 100%;
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
}

.card_turbo {
  overflow: hidden;
  max-width: 1050px !important;
  .modal-header {
    padding: 1rem 1rem 0;
  }
  .modal-body {
    padding: 0;
  }
  .modal-header {
    border-bottom: 0;
  }
  .card_tabs_first_panel {
    padding: 0 !important;
    border-bottom: 0 !important;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    min-height: 648px;
    @media (max-width: 767px) {
      min-height: unset;
    }
  }
  .react-tabs__tab-panel {
    border-left: 0;
    border-right: 0;
  }
  .react-tabs__tab {
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }

  .actions_card {
    border-bottom: 1px solid $gray-500;
    background-color: $gray-0;
    padding: 1rem;
  }

  .description {
    font-size: small;
    border-bottom: 1px solid $gray-500;
    margin-bottom: 1rem;
  }

  .card_note {
    margin-top: 5rem;
    font-size: small;
    margin-bottom: 0;
  }
  .radio_buttons {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    label {
      margin: 0;
      margin-left: 0.5rem;
      cursor: pointer;
      pointer-events: auto;
      color: $gray-500;
    }
    input[type='radio']:checked + label {
      color: $white;
      cursor: default;
    }
  }

  .rangeslider .rangeslider__handle-tooltip {
    width: 25px;
    height: 25px;
    font-size: 12px;
    z-index: 6565656565;
  }
  .rangeslider .rangeslider__handle-tooltip span {
    margin-top: 6px;
    display: inline-block;
    line-height: 100%;
  }

  .rangeslider-horizontal {
    height: 6px;
    margin: 0.7rem 0;
    .rangeslider__fill {
      box-shadow: none;
    }
    .rangeslider__handle {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      outline: none;
      &:after {
        width: 10px;
        height: 10px;
        top: calc(50% - 5px);
        left: calc(50% - 5px);
      }
      .rangeslider__handle-tooltip {
        top: -30px;
        position: relative;
        z-index: 5555555;
        padding: 0 5px;
        width: fit-content;
        &::after {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid rgba(0, 0, 0, 0.8);
          left: 50%;
          bottom: -6px;
          transform: translate3d(-50%, 0, 0);
        }
      }
    }
  }

  .gpu_info.first {
    margin-bottom: 0.5rem;
    @media (max-width: 767px) {
      margin-top: 0;
      border-top: 0;
    }
  }
  .gpu_info {
    .gpu_memory {
      font-weight: 500;
      font-size: 0.8em;
      margin-top: -5px;
    }
    .gpu_name {
      font-weight: 500;
      font-size: 0.94em;
      margin-left: 0.4em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 57%;
      color: #01b95d;
    }
    .gpu_number {
      display: flex;
      font-size: 0.8em;
      color: $gray-500;
    }
    @media (max-width: 767px) {
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 1px solid $gray-500;
    }
  }
  .detailed_cards {
    max-height: 257px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    .form_control_react_select__menu {
      position: absolute;
    }
  }
}

.cardTweak {
  display: flex;
  align-items: center;
  button {
    margin-left: 10px;
    padding: 0.3rem 0.5rem;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}

.cardTweakString {
  transition: all 0.7s;
  overflow: hidden;
  position: relative;
}
.cardTweakString__Buttons {
  margin-top: 10px;
  display: flex;
  button:last-child {
    margin-left: 10px;
  }
}

.card_algorithm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.all_profiles_button {
  position: absolute;
  left: 0;
  top: 0;
  width: 120px;
  z-index: 5;
}

.all_profiles {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $gray-200;
  transition: all 0.5s;
  transform: translateX(-120%);
  display: flex;
  flex-direction: column;
  width: 100%;
  &.showProfiles {
    transform: translateX(0);
  }
  z-index: 50;
  .modal-body {
    overflow-y: auto;
  }
}

.advanced_card_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0;
  &:hover {
    .advanced_card_field__arrows {
      display: flex !important;
      margin: 0 1px 0 -15px !important;
      z-index: 10;
    }
  }
}

.advanced_card_field {
  padding: 0 0.5%;
  flex: 1;
  margin-bottom: 0.5rem;
  &.advanced_card_field__select {
    max-width: 85px;
  }
  .form_control_react_select__control {
    padding: 0 !important;
  }
  .form_control_react_select__indicators {
    margin: 0 !important;
  }
  .form_control_react_select__dropdown-indicator {
    padding: 7px !important;
  }
}

.general_nvidia,
.general_amd {
  @media (max-width: 768px) {
    .advanced_card_field {
      flex: none;
      width: 50%;
    }
    .advanced_card_row {
      flex-wrap: wrap;
    }
    .advanced_card_field__select {
      width: 30%;
      min-width: 30%;
      max-width: unset;
    }
  }
}

.detailed_cards .advanced_card_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .advanced_card_field__arrows {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: -1px 0 -1px 5px;
  }
  .advanced_card_field__arrows_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.3s;
    padding: 3px;
    font-size: 9px;
    &:first-child {
      margin-bottom: 4px;
    }
    &:hover {
      background-color: $blue;
      color: white;
    }
  }
}

.detailed_cards {
  @media (max-width: 768px) {
    .advanced_card_field {
      flex-direction: column;
      width: 50%;
      min-width: 50%;
      align-items: flex-start;
    }
    .advanced_card_field__select {
      width: 30%;
      min-width: 30%;
      max-width: unset;
    }
    .advanced_card_row {
      flex-wrap: wrap;
      &:hover .advanced_card_field__arrows {
        display: none !important;
      }
    }
  }
}

.videoCards_errors {
  text-align: left;
  margin-bottom: 15px;
  font-size: 0.9em;
}

.workers_header__info {
  width: 100%;
  background-color: #36394c;
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  @media (max-width: 1400px) AND (min-width: 1200px) {
    font-size: 12px;
  }
}
.workers_header__info_header {
  width: 100%;
  font-weight: 700;
  margin-bottom: 5px;
  border-bottom: 1px solid $gray-500;
}
.workers_header__info_parts {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 768px) AND (min-width: 576px) {
    flex-direction: column;
  }
}
.workers_header__info_part {
  width: 100%;
  &:nth-child(2) {
    padding: 0 10px;
    border: 1px dashed $gray-500;
    border-bottom: 0;
    border-top: 0;
  }
  &:first-child {
    padding-right: 10px;
  }
  &:last-child {
    padding-left: 10px;
  }
  @media (max-width: 768px) AND (min-width: 576px) {
    &.workers_header__info_part_visible {
      display: none;
    }
    padding: 0;
    &:nth-child(2) {
      padding: 10px 0;
      border: 1px dashed $gray-500;
      border-left: 0;
      border-right: 0;
    }
    &:first-child {
      padding-bottom: 10px;
    }
    &:last-child {
      padding-top: 10px;
    }
  }
  @media (max-width: 576px) {
  }
  &:nth-child(2) {
    border-right: 0;
  }
}
.workers_header__info_dc_room {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workers_header__info_row {
  position: relative;
  &::after {
    font-size: 12px;
    min-width: 21em;
    white-space: unset;
    z-index: 1003;
  }

  div {
    display: flex;
    align-items: center;
  }
  .workers_header__info_icon {
    font-size: 24px;
    margin-right: 15px;
  }
  .workers_header__info_icon_svg {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-color: $gray-400;
    mask: url('../../../images/rig.svg') no-repeat center;
    mask-size: cover;
  }
  .workers_header__info_icon_svg_gpu {
    mask: url('../../../images/gpu.svg') no-repeat center;
    mask-size: cover;
  }
}

.workers_header__info_part_algo {
  .workers_header__info_row div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  span {
    text-transform: uppercase;
  }
  @media (max-width: 576px) {
    display: none;
  }
}

.workers_filters__elements__more {
  //position: relative;
  transition: max-height 0.6s;
  z-index: 102;
}
.workers_more_filters {
  display: inline-block;
  cursor: pointer;
  margin-top: 5px;
}
.workers_filters__buttons {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  button {
    font-size: 12px;
    text-align: left;
    width: auto;
    min-width: unset;
    margin: 5px;
    i {
      margin-right: 3px;
    }
  }
}

.card__memInfo {
  color: $gray-500;
}

.favoriteFirst {
  justify-content: flex-end !important;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &:nth-child(2) {
    margin-left: 10px;
  }
  @media (max-width: 991px) {
    &:nth-child(1) {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    justify-content: flex-start !important;
    margin-bottom: 10px;
  }
}

.advanced-mobile {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  h5 {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 0 0 5px 5px;
    padding-right: 5px;
  }
  .doubleArrows{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.7em;
      padding: 0.1rem 0.3rem;
      margin: 0 0.1rem;
      width: 80px;
      &:first-child{
        i{
          margin-right: 0.3rem;
        }
      }
      &:last-child{
        i{
          margin-left: 0.3rem;
        }
      }
    }
  }
}

.overclocking-default {
  .row > .col {
    display: flex;
    flex-wrap: wrap;
  }
}
.overclocking-default,
.overclocking-advanced,
.overclocking-additional {
  min-height: 350px;
}
.gpu-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 10px;
  .gpu-block {
    font-size: 10px;
    width: 10.7%;
    max-width: 50px;
    height: 30px;
    margin: 2px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    background-color: $gray-300;
    cursor: pointer;
    span {
      font-size: 0.9em;
    }
    &.active {
      width: 50px;
      .form-control {
        height: 35px;
        padding: 3px 10px;
        font-size: 12px;
      }
    }
  }
}
.gpu-description {
  position: absolute;
  top: 97%;
  left: 0;
  display: flex;
  font-size: 10px;
  & > div {
    margin-right: 5px;
    white-space: nowrap;
  }
  & > div:last-child {
    white-space: break-spaces;
    text-align: right;
  }
  span {
    font-size: 10px !important;
  }
}

.workerActionsMobile {
  display: none;
}

.profiles_tab {
  .advanced-mobile {
    max-height: 50vh;
  }
}

.select-profile {
  position: absolute;
  left: 0.5rem;
  top: -50px;
  @media (max-width: 767px) {
    position: static;
    margin-bottom: 5px;
  }
}

.profiles-list {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 200px;
  transform: translateX(-210px);
  height: 510px;
  transition: transform 0.5s;
  background-color: $gray-200;
  .profiles_list__profiles {
    overflow-y: auto;
    height: calc(100% - 50px);
  }
  @media (max-width: 767px) {
    height: 60vh;
    top: 36px;
  }
  &.show {
    transform: translateX(0);
  }
  .quicksearch {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    .form-control {
      padding: 0.47rem 0.75rem;
    }
  }
  .overclocking-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: $gray-300;
    }
  }
}

@media (max-width: 1500px) {
  .workers__table {
    font-size: 12px;
  }
  .workerActionsMobile {
    display: block;
  }
  .workerActions {
    display: none;
  }
}

@media (max-width: 768px) {
  .card_turbo .react-tabs__tab-panel {
    padding: 5px 3px !important;
  }
  .card_turbo .modal-title {
    margin-bottom: 5px;
  }
  .card_turbo .actions_card {
    padding: 0.3rem 0.7rem;
  }
  .general-locked {
    font-size: 10px;
    button {
      font-size: 10px;
      padding: 5px;
    }
  }
  .card_turbo .card_note {
    margin-top: 5px;
    font-size: 10px;
  }
  .card_turbo .description {
    font-size: 12px;
  }
  .workers_header__info {
    font-size: 12px;
  }
  .workers_header__info_row .workers_header__info_icon {
    font-size: 20px;
    margin-right: 5px;
  }
  .workers_header__info_row .workers_header__info_icon_svg {
    width: 24px;
    height: 24px;
  }
  .mobile_workers__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    background-color: $gray-300;
    margin-bottom: 0.5rem;
    font-size: 12px;
    .worker_actions_mobile {
      margin-left: auto;
    }
    .dropdown-item:disabled {
      pointer-events: all;
    }
    .worker_uptime {
      //position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }
    .worker_cards {
      margin-bottom: 2px;
    }
    .dropdown-menu[x-placement^='right'],
    .dropdown-menu[x-placement^='top'],
    .dropdown-menu[x-placement^='left'] {
      bottom: 0 !important;
    }

    .remove_from_favorite,
    .add_to_favorite {
      position: relative !important;
      cursor: pointer;
    }
    .add_to_favorite {
      display: block;
    }
    .worker_statuses {
      margin-left: 5px;
    }
  }
  .worker_info__values {
    display: flex;
    align-items: center;
    span {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
  .mobile_workers__item_info {
    display: flex;
    flex-direction: column;
  }
  .worker_hw {
    margin-right: 3px;
  }
  .worker_name {
    position: relative;
    display: flex;
    align-items: center;
    width: 90px;
    font-size: 12px;
    margin-bottom: 3px;
    input[type='checkbox'] {
      margin-right: 5px;
      background-color: $gray-50;
      border-color: $gray-50;
    }
  }
  .worker_coin_icon {
    width: 16px;
    margin-right: 3px;
  }
}

.scheduleLogs{
  overflow-y: auto;
  max-height: 500px;
}