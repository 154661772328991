.modal-box {
  max-width: 1100px;
}

.modal-header-custom {
  height: 2rem;
  // margin-bottom: 1rem;
  padding-top: 0.5rem;
}

.modal-header-custom_sub {
  height: 5.8rem;
  margin-bottom: 0;
}

.modal-heading {
  position: absolute;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal-btn {
  width: 120px;
  padding: 0.4rem 0.8rem;
}

.modal-btn:not(:last-child) {
  margin-right: 1rem;
}

// common form styles
.modal-form {
  color: #ccc;
}
.modal-form__row {
  width: 100%;
}
.modal-form__col {
  margin: 0;
  padding: 0 1.5rem;
}
.modal-form__item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.modal-form__label {
  white-space: nowrap;
  width: 30%;
  margin: 0;
}
.modal-form__input {
  width: 30%;
  padding: 0.5rem 0.75rem !important;
  margin-right: 3rem;
}
.modal-form__check {
  width: 30%;
  height: 2.2rem;
  display: flex;
  align-items: center;
}
.modal-form__checkbox {
  margin-right: 1rem !important;
}
.modal-form__btn {
  padding: 0.32rem 1rem !important;
  color: #ccc !important;
}

/////////////// mobile_version
@media (max-width: 767px) {
  .modal-box {
    max-width: 98%;
    max-height: 84vh !important;
    height: 84vh;
    margin: 0 auto;
  }
  .modal-header-custom {
    height: auto;
    margin-bottom: 2rem;
    padding-top: 1rem;
  }

  .modal-header-custom_sub {
    height: 5.8rem;
    margin-bottom: 0;
  }
  .modal-heading {
    h4 {
      font-size: 18px;
    }
  }

  .modal-btn {
    width: auto;
    font-size: 24px;
    white-space: nowrap;
    padding: 0.4rem 1.5rem;
  }
  .modal-btn:not(:last-child) {
    margin-right: 30px;
  }
}
