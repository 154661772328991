.react-tabs__tab {
    padding: 0.5rem;
    flex-grow: 1;
    text-align: center;
    &:focus {
        outline: none!important;
        box-shadow: none!important;
        border-color: $gray-500!important;
        &:after {
            content: none!important;
        }
    }
    @media (max-width: 768px) {
        font-size: 14px!important;
        letter-spacing: normal!important;
    }
}


.react-tabs__tab--selected {
    background: $gray-0!important;
    color: inherit!important;
    border-color: $gray-500!important;
}
.react-tabs ul {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}
.react-tabs__tab-panel {
    border: 1px solid $gray-500;
    background-color: $gray-0;
    padding: 1rem;
}

