.form_control_react_select__control {
    background-color: $gray-300 !important;
    color: $gray-500 !important;
    border: 1px solid #363e54 !important;
    padding: 0.5rem 0 0 !important;
}

.form_control_react_select__control--is-focused {
    background-color: #30384b !important;
    border-color: #3a425a !important;
    box-shadow: none !important;
}

.form_control_react_select__menu {
    z-index: 500000 !important;
    background-color: $gray-300 !important;
    color: $gray-500 !important;
    border: 1px solid #363e54 !important;
    margin: 0 !important;
}

.form_control_react_select__single-value {
    img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }

    display: flex;
    align-items: center;
    color: $gray-500 !important;
}

.form_control_react_select__option {
    img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }

    display: flex;
    align-items: center;
    color: $gray-500 !important;
}

.form_control_react_select__option--is-focused {
    background-color: $gray-0 !important;
}
.form_control_react_select__option--is-selected {
    background-color: $gray-50 !important;
}
.form_control_react_select__multi-value__label{
    color: $gray-500!important;
}

.form_control_react_select__indicators {
    margin-top: -0.28rem;
    margin-bottom: 0.18rem;
}
.form_control_react_select__multi-value{
    background-color: $gray-0!important;
}

.form_control_react_select__indicators, .form_control_react_select__indicator {
    color: $gray-500 !important;
    span {
        background-color: $gray-500 !important;
    }
}
.form_control_react_select__clear-indicator{
    margin: 0 -5px 0 -10px;
}
.form_control_react_select__indicator-separator{
    display: none;
}


.form_control_react_select__value-container {
    padding-bottom: 0 !important;
}

.form_control_react_select__placeholder, .form_control_react_select__input {
    color: $gray-500 !important;
}

.special_select .form_control_react_select__indicators {
    margin-right: 30px;
}
.mining_preset__configure_miner .special_select .form_control_react_select__indicators {
     margin-right: 0;
}