.attention-status {
  animation: blink 2s linear infinite;
  color: #ec4561;
  margin-left: 0.5rem;
}
@keyframes blink {
  0% {
    color: #ec4561;
  }
  50% {
    color: #ec456100;
  }
  100% {
    color: #ec4561;
  }
}

.mobile-actionbox {
  display: flex;
  width: 26.4%;
  justify-content: space-between;
}

.chart-line {
  height: 160px;
  max-width: 72%;
  margin-bottom: 10px;
}

.chart-pie {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 30%;
  // margin-top: -10px;
  padding-bottom: 3px;
}

.chart-pie__pie {
  width: 100%;
  height: 130px;
}

.chart-pie {
  width: 30%;
}

.chart-zoom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 0 12px;
  z-index: 99999;
}

.chart-zoom__btn {
  border: none;
  border-radius: 2px;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.78rem;
  font-weight: 500;
  background: #4e525e;
  color: #a8b2bc;
  &:hover,
  &_active {
    width: 1.4rem;
    height: 1.4rem;
    font-size: 0.78rem;
    background: #626ed4;
    color: #fff;
  }
}

.action-items__box {
  display: flex;
  margin-top: 4px;
}

.actions__item_rig {
  margin: 0 5px 5px 0;

  &:hover {
    margin: 2px 3px;
    margin: 2px;
    width: 24px;
    height: 24px;
    margin: 0 4px 5px 0;
  }
}

.actions__item_card {
  width: 22px;
  height: 22px;
  &:hover {
    margin: 2px;
    width: 22px;
    height: 22px;
  }
}

.actions__item_delete-rig {
  color: #ec4561;
  &:hover {
    background-color: #ec4561;
  }
}

.actions__items_card {
  display: flex;
  justify-content: center;
}

////////// activity logs
.worker-block_logs {
  margin: 0;
  padding: 0.5rem !important;
}
.active-log__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.3rem 0.5rem;
  transition: all 0.5s ease-out;
  width: 100%;
  &:hover {
    background: #2e3648;
  }
  &_error {
    &:hover {
      background: #ec45612f;
    }
  }
}
.active-log {
  line-height: 1.5;
  width: 74%;
  margin: 0;
  color: #949fff;
}

.active-rig_console {
  font-size: 0.75rem;
  color: #a8b2bc;
  opacity: 0.5;
  margin: 0;
}
.active-log__time {
  width: 14%;
  font-size: 0.75rem;
  color: #a8b2bc;
  opacity: 0.5;
  margin: 0;
  &_ago {
    text-align: right;
    width: 12%;
  }
}
.active-log__descr {
  font-size: 0.75rem;
  margin: 0;
}

.form-item__checkbox_border {
  border: 1px solid #2e3648 !important;
}

.card__info {
  display: flex;
  text-align: left;
  line-height: 1.2;
  font-size: 0.7rem;
  white-space: nowrap;
  padding-left: 0.7rem !important;
}
.card_slot {
  font-size: 0.6rem;
  margin: 0;
  line-height: 1.3;
}
.card__num {
  margin-right: 0.8rem;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
}
.card__name {
  font-size: 0.8rem;
  color: #01b95d;
  &_amd {
    color: #e4909c;
  }
}
// .card__status {
//   margin-right: 10px !important;
// }
.card__vendor {
  color: #949fff;
}
.card__power {
  color: #d3d018;
}
.card__memType {
  color: #18cdd3;
}
.card__off {
  color: #ec4561;
  margin-left: 0.5rem;
}

.card__info-total {
  font-weight: 500;
  color: #e9ecef;
}

.worker-tab {
  text-align: center;
  font-size: 0.8rem;

  th {
    vertical-align: baseline !important;
    font-weight: 500;
  }
}

.copy-btn {
  background: #a8b2bc;
  border: 1px #a8b2bc solid;
  padding: 1px 5px;
  border-radius: 3px;
  color: #343b51;
  margin: 0 0.5rem;
  line-height: 1;
  // transition: all 0.2s ease-out;
  &_active {
    background: transparent;
    border: none;
    color: #a8b2bc;
    margin-left: 0;
  }
}

.worker-block {
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 60vh;
}

.worker-block_table {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 220px;
  .thead-light {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.worker-information {
  display: flex;
  justify-content: space-between;
}
.worker-information__block,
.worker-information__miner-info {
  color: #ccc;
  font-size: 12px;
  line-height: 1.4;
  margin-top: -10px;
  h4 {
    font-size: 16px;
    margin: 0 0 4px 6px;
    line-height: 1.5;
  }
  b {
    color: #ccc;
  }
  span {
    color: #a8b2bc;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      color: #ccc;
    }
  }
}
.worker-information__miner-info {
  // text-align: center;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.worker-information__row {
  display: flex;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.worker-information__col {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.worker-form_row {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.worker-form__col {
  width: 100%;
  max-width: 100%;
  padding: 0;
  &_bordered {
    border-bottom: 1px solid #2e3648;
    padding-bottom: 0.4rem;
    margin-bottom: 0.8rem;
  }
}

.worker-form__btn {
  color: #ccc;
  padding: 4.5px 0.75rem !important;
}

.worker-form__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  &_id {
    margin-bottom: 0;
  }
}

.worker-form-item__label {
  width: 30%;
  text-align: left;
  margin: 0.1rem 1rem 0 0;
  color: #ccc;
  white-space: nowrap;
}

.worker-item__input {
  width: 30%;
  margin-right: 1rem;
  &_s {
    width: 20%;
    margin-right: 1rem;
  }
}

.worker-item__input_multi {
  display: flex;
}

.worker-item__area {
  width: 49%;
  margin-right: 1rem;
}

.worker-item__input-field {
  padding: 4.5px 0.75rem !important;
  overflow: hidden;
}

.worker-item__input-field_s {
  width: 3rem;
  margin-right: 0.7rem;
}

.worker-item__error {
  font-size: 0.65rem;
  color: #ec4561;
}

.worker-form__value {
  margin: 0.2rem 1rem 0.2rem 0;
  padding-left: 0.7rem;
  color: #ccc;
  &_check {
    font-size: 0.67rem;
    white-space: nowrap;
    margin: 0 1rem 0 -0.5rem;
    color: #9ca8b3;
  }
  span {
    margin-left: 1rem;
  }
}

.worker-charts__row {
  display: flex;
  align-items: center;
}

.rig-status {
  position: absolute;
  top: -1.15rem;
  left: 6rem;
}
.rig-status__date {
  color: #ffd783;
}

.rig-check {
  position: absolute;
  top: -1.15rem;
  right: 6rem;
}

.rig-check__date {
  color: #ffd783;
}

///////////////////////// checklist
.checklist__ignore-modal {
  max-width: 294px !important;
  text-align: center;
  font-size: 1rem;
}

.checklist-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #2e3648;
  color: #000;
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;
  background: #ffd783;
  margin-bottom: 0.4rem;
  &_red {
    background: #ff5d55;
  }
  &:hover {
    opacity: 0.95;
  }
}

.checklist-status {
  display: flex;
  align-items: center;
  margin: 0;
  line-height: 1;
}

.checklist-status__icon {
  font-size: 1.2rem;
  width: 30px;
  margin-right: 10px;
  font-weight: 800;
}

.checklist-row__descr {
  margin: 0;
  @media (max-width: 400px) {
    word-break: break-word;
  }
}

.checklist-row__nav {
  display: flex;
}

.checklist-row__nav-btn {
  margin-left: 0.5rem;
  border-radius: 4px;
  border: 1px solid #2e3648;
  background: #ebebeb;
  padding: 0.1rem 1rem;
  &:hover {
    opacity: 0.7;
  }
}

.green {
  color: #49d159 !important;
}
.yellow {
  color: #d3d018 !important;
}
.orange {
  color: #f8b425 !important;
}
.red {
  color: #ec4561 !important;
}
.white {
  color: #fff !important;
}
/////////////// mobile_version
@media (max-width: 767px) {
  .worker-block_table {
    max-height: 30vh;
  }
  .react-tabs__tab-panel {
    padding: 5px !important;
  }
  .modal-footer {
    padding: 0 10px !important;
  }

  .modal-heading {
    top: 0.5rem;
  }
  .rig-status,
  .rig-check {
    position: static;
    top: -1rem;
    font-size: 12px;
  }
  .rig-check {
    right: 1rem;
    margin-left: auto;
  }
  .rig-status {
    left: 1rem;
  }

  .worker-info-modal .modal-body {
    display: flex;
    flex-wrap: wrap;
  }

  .mobile-actionbox {
    width: auto;
    max-width: 100%;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .chart-line {
    height: 100px !important;
    margin-bottom: 10px;
    max-width: 100%;
  }

  .chart-pie {
    display: none;
  }

  .chart-zoom {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  .chart-zoom__btn,
  .actions__item_rig {
    border-radius: 5px;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }
  .chart-zoom__btn {
    margin-right: 0;
    width: 50px;
    height: 20px;
    margin-bottom: 4px;
    &:hover,
    &_active {
      width: 50px;
      height: 20px;
      font-size: 10px;
      background: #626ed4;
      color: #fff;
    }
  }

  .action-items__box {
    justify-content: center;
    width: 100%;
    margin: 0 0 10px;
    div {
      display: flex;
    }
  }

  .react-tabs__tab {
    font-size: 12px !important;
    letter-spacing: 1px;
    white-space: nowrap;
  }

  .worker_charts {
    margin-bottom: 5px;
    flex-wrap: nowrap;
  }

  .worker-tab {
    max-width: 100%;
    font-size: 10px;
  }

  .card__info {
    flex-direction: column;
    font-size: 10px;
  }
  .card__info-data {
    display: flex;
    flex-direction: column;
  }
  .card_slot {
    font-size: 10px;
    margin: 0 0 0 10px;
    line-height: 1.25;
  }
  .card__num {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .card__name {
    font-size: 10px;
    color: #01b95d;
    &_amd {
      color: #e4909c;
    }
  }

  .card__vendor {
    color: #949fff;
  }
  .card__power {
    color: #d3d018;
  }
  .card__off {
    color: #ec4561;
    margin-left: 0.5rem;
  }
  .worker-information__block {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    h4 {
      font-size: 13px;
    }
  }
  ///////// active-logs
  .active-log,
  .active-log__descr {
    font-size: 12px;
  }
  .active-log__time {
    font-size: 12px;
    width: 20%;
  }
  .active-log {
    margin: 0 0 0 10px;
    width: 59%;
    word-break: break-word;
  }

  ///////// settings
  .worker-form_row {
    padding: 0 5px;
  }
  .worker-info-modal {
    .worker-form-item__label,
    .form-item__input,
    .worker-form__value,
    .form-control,
    .worker-form__btn {
      font-size: 10px;
      white-space: normal;
    }
  }
  .worker-form__item {
    justify-content: space-between;
  }
  .worker-form-item__label {
    width: 35%;
  }
  .worker-form__value_check {
    white-space: normal;
    font-size: 8.5px;
    margin: 0 0 0 -10px;
  }
  .worker-form__btn {
    white-space: nowrap;
  }
  .active-log {
    margin: 0 0 0 10px;
  }

  ///////// checklist
  .checklist-row {
    font-size: 12px;
  }
  .checklist-row__nav {
    flex-direction: column;
  }
  .checklist-row__nav-btn {
    margin: 5px 0 0 10px;
  }
}

.worker-block,
.worker-setting-form {
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 450px;
}
@media (max-width: 380px) {
  .react-tabs__tab {
    font-size: 10px!important;
  }
}
@media (max-width: 768px) {
  .worker-block,
  .worker-setting-form {
    height: 50vh;
  }
  .worker-information {
    margin-top: 15px;
  }
  .card__memType {
    word-break: break-all;
  }
  .worker-info-modal {
    .modal-header-custom {
      margin-bottom: 0.5rem;
    }
    .modal-header .close {
      margin: -1.5rem -1rem -0.5rem auto;
    }
  }

  .mobile-actionbox {
    .actions__item_rig {
      display: none;
    }
  }
  .chart-line-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 0;
    .chart-line {
      width: calc(89vw - 50px);
      flex-grow: 0;
    }
  }
  .worker-info-main-information {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 12px;
    width: 100%;
    margin-bottom: 5px;
    .wimi_coin {
      display: flex;
      align-items: center;
    }
    .wimi_power,
    .wimi_miner,
    .wimi_pool {
      margin-left: 18px;
    }
  }

  .mobile-worker-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 56px;
    .actions__item_rig {
      margin: 2px;
      width: 24px;
      height: 24px;
      font-size: 14px;
    }
    @media (max-width: 400px) {
      width: 100%;
      justify-content: flex-end;
    }
  }

  .worker-card-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    background-color: #2e3648;
    margin-bottom: 0.5rem;
    font-size: 12px;
    transition: height 0.5s;

    .worker-card-mobile__information-full,
    .worker-card-mobile__actions {
      display: none;
    }
    &.show-full {
      .worker-card-mobile__information {
        min-width: 105px;
      }
      .worker-card-mobile__information-full {
        flex-wrap: wrap;
        &:last-child {
          font-size: 0.9em;
        }
      }
      .worker-card-mobile__information-full,
      .worker-card-mobile__actions {
        display: flex;
      }
      .worker-card-mobile__information-minified {
        display: none;
      }
    }
  }
  .worker-card-mobile__information {
    display: flex;
    flex-direction: column;
  }
  .worker-card-mobile__actions-errors {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .worker-card-mobile__actions {
      margin-top: 5px;
    }
  }
}

.worker-card-mobile {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.worker-card-mobile__actions-errors {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  .worker-card-mobile__actions {
    margin-top: 5px;
  }
}

/////////////////// RIG CONSOLE ///////////////////
.rig_console {
  font-size: 0.85rem;
  color: #fff;
  opacity: 0.9;
  margin: 0;
  line-height: 1.4;
  background-color: #697a9e;
  div{
    text-wrap: wrap;
    word-break: break-word;
    span{
      color: #fff;
      word-break: break-word;
    }
  }
  @media (max-width: 767px) {
    div{
      margin-bottom: 0.7rem;
    }
  }
}
