// 
// _table.scss
// 

.table {
    position: relative;
  th {
      font-weight: $font-weight-bold;
      position: relative;
      vertical-align: middle;
    white-space: nowrap;
  }
    td{
        vertical-align: middle;
    }

}
.thead-light{
    background-color: #32394e;

}
//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}
.table_container{
    position: relative;
}
.sticky_header{
    position:-webkit-sticky!important;
    position:sticky!important;
    top: 69px;
    z-index: 100;
}

.dataTables_entries{
  display: flex;
  align-items: center;
    margin-bottom: 1rem;
  select{
        margin: 0 0.5rem;
      max-width: 80px;
  }
    label{
        margin-bottom: 0;
    }
}

.column_sort{
    position: absolute;
    right: 0.3rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    opacity: .4;
}
.column_sort_direction{
  position: absolute;
  right: 0.3rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.dataTable__container{
    padding-bottom: 1rem;
    width: 100%;
}
.tableCheckbox{
    text-align: center;
}

.add_to_favorite_column{
    position: relative;
}


.add_to_favorite_column:hover{
    .add_to_favorite{
        display: block;
    }
}
.remove_from_favorite,.add_to_favorite{
    position: absolute!important;
    right: 3px;
    top: 25%;
    cursor: pointer;
  @media (max-width: 767px) {
    right: 0;
    top: 0;
  }
}
.add_to_favorite{
    display: none;
}

.sorting_column{
    cursor: pointer;
  padding-right: 15px!important;

}
.dataTables_paginate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  @media (max-width: 1199px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-bottom: 10px;
  }
}


.quicksearch{
  max-width: 200px;
  position: relative;
  margin-right: 10px;
  .fa-times{
      position: absolute;
      right: 0.5rem;
      top: 35%;
      cursor: pointer;
  }
  input::placeholder{
      color: $gray-500;
  }
  @media (max-width: 1199px) {
    margin: 10px 0;
  }
}

.page-link{
  padding: 0.58rem 0.75rem;
}
.pagination{
  margin: 0;
}
@media (max-width: 768px) {
    .pagination,.quicksearch{
        width: 100%;
        max-width: unset;
      margin-bottom: 0;
    }
    .pagination{
        display: flex;
        justify-content: flex-end;
        margin: 10px 0 0;
        .page-link {
            padding: 0.38rem 0.65rem;
        }
    }
    .dataTables_paginate{
        display: flex;
        flex-direction: column-reverse;
    }

}
@media (max-width: 576px) {
  .dataTables_entries{
    justify-content: flex-end;
    margin-bottom: 0;
  }
}