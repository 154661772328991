.download_page{
  padding-top: 2rem;
  color: $gray-500;
  padding-bottom: 3rem;
}

.supported_cards{
  margin-top: 2rem;
  h7{
    margin-bottom: 1rem;
  }
  table{
    cursor: default;
  }
  td,tr{
    transition: all 0.2s;
  }
  td:hover{
    background-color: #555876;
    color: white;
  }
  @media(max-width: 767px){
    font-size: 0.9em;
  }
}


.wallets{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  margin: auto;
}




.wallet{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 16px;
  border-radius: 15px;
  padding: 5px 10px;
  transition: all 1s ease-out;
  cursor: pointer;
  background: linear-gradient(to left, $gray-300 50%, $gray-600 50%) right;
  background-size: 2000px 100%;
  &:hover{
    color: initial;
    background-position: left;
    & .wallet__address{
      background-position: left;
    }
    & .wallet__icon{
      transform: rotate(360deg);
      transition: all 1s;
    }
  }
}

.wallet__address{
  overflow: hidden;
  background: linear-gradient(to right, white, white 50%, $gray-500 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 2000px 100%;
  background-position: right;
  transition: background-position 1s ease-out;
  text-decoration: none;
}


.wallet__icon{
  img{
    width: 60px;
  }
  margin-right: 10px;
}
