.modal-body label{
    z-index: 5;
    position: relative;
    font-size: .8rem;
    margin-bottom: -19px;
    display: block;
    margin-left: 5px;
    color: $gray-1100;
    pointer-events: none;
}
.modal-body .form-control{
    padding: 1rem .75rem .3rem;
    height: auto;
}

.modal-header{
    padding-bottom: 0;
}

.sweet-alert {
    .sweet-alert-icon{
        font-size: 70px;
        padding: 0.3em;
        color: $white;
    }
}

@media(max-width: 768px){
    .modal-title {
        font-size: 14px;
    }
    .modal-body{
        padding: 0.5rem;
    }
    }