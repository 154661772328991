//////////////// DC Page:
.dc-container-fluid {
  padding-top: 24px;
  text-align: center;
}

.dc-top {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 0.5rem;
}

.dc-total {
  font-weight: 700;
}

.actions__item_dc:focus {
  background-color: #4e525e;
  color: #a8b2bc;
  box-shadow: none;
  border: none;
}

.actions__item_dc:hover {
  background-color: $blue;
  color: $white;
  box-shadow: none;
  border: none;
}

//////////// Common Modals:
.form-item {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.form-item__input {
  width: 50%;
}

.form-item__label {
  text-align: right;
  margin: 0.6rem 1rem 0 0;
  color: #ccc;
  white-space: nowrap;
}

.dc-input {
  padding: 0.6rem 0.75rem !important;
  height: auto;
  overflow: hidden;
  height: fit-content;
}

@media (max-width: 767px) {
  .form-content {
    display: block;
  }

  .dc-form-group {
    width: 100%;
    max-width: 100%;
  }

  .form-item {
    display: block;
    margin-bottom: 1rem;
  }

  .form-item__input {
    width: 100%;
  }

  .form-item__label {
    text-align: left;
    margin: 0;
  }
}

///////////// Statistics Modal:
.dc-stats {
  display: flex;
  justify-content: center;
  padding: 0 2%;
  margin-bottom: 1rem;
}

.dc-stats__col {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: flex-start;
  &:not(:last-child) {
    margin-right: 10%;
  }
}

.dc-stats__item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.8rem;
}

.dc-stats__item_down {
  border-top: 2px solid #363e54;
  padding-top: 0.8rem;
}

.dc-stats__item-label {
  margin: 0 1rem 0 0;
  flex: 1 1 auto;
}

.dc-stats__item-data {
  margin: 0;
  text-align: right;
}
.dc-stats__chart {
  width: 100%;
  margin: 1rem 1rem 4rem;
}

//////////// Delete Modal:
.form-agree {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
}

.form-item__check {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-item__checkbox {
  margin-right: 1rem !important;
  cursor: pointer;
  &:hover {
    background-color: #2e3648;
  }
}

.form-item__checkbox_error {
  border: 1px solid $red !important;
  margin-right: 1rem !important;
  cursor: pointer;
}



.dc__table {
  @media (max-width: 768px){
    th:last-child{
      display: none;
    }
    .actions__items{
      flex-direction: row;
    }
    tr{
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding-right: 30px;
      min-height: 35px;
      &:not(:last-child){
        border-bottom: 1px solid $gray-500;
      }
      td{
        border: none;
        word-break: break-word;
      }
      td:last-child{
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
      }
    }
  }
  @media (max-width: 500px){
    tr{
      min-height: 90px;
    }
    .actions__items{
      flex-direction: column;
    }
  }
}