.actions__items {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.actions__item {
  margin: 2px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4e525e;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  color: #a8b2bc;
  padding: 0;
  &:hover {
    background-color: $blue;
    color: white;
    margin: 1px 1px;
    width: 26px;
    height: 26px;
  }
}

.actions__item-no-hover {
  margin: 2px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4e525e;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  color: #a8b2bc;
  padding: 0;
  &:hover {
    background-color: $blue;
    color: #ec4561;
    margin: 1px 1px;
    width: 26px;
    height: 26px;
  }
}
@keyframes itemChange {
  from {
    background-color: #4e525e;
    margin: 2px;
    width: 24px;
    height: 24px;
    color: #a8b2bc; /* Initial color */
  }
  to {
    background-color: $blue;
    color: #ec4561; /* Target color */
    width: 26px;
    height: 26px;
    margin: 1px 1px;
  }
}

.table-responsive {
  tr:nth-last-child(-n + 2) {
    .dropdown-menu.show {
      transform: none !important;
      left: auto !important;
      right: 100% !important;
      top: auto !important;
      bottom: 100% !important;
    }
  }
}
