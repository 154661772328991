// Light Theme
/*@import './bootstrap.scss';
@import './app.scss';*/

// Dark Theme
@import './bootstrap-dark.scss';
@import './app-dark.scss';

//RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

@import './icons.scss';

@import './animate.scss';

// Theme Custom Scss for react version
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}
.rdw-editor-main {
  border: 1px solid $card-border-color !important;
  border-radius: 4px !important;
  min-height: 130px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}
/*
.g-recaptcha {
  position: relative;
  width: 100%;
  background: #f9f9f9;
  overflow: hidden;
}

.g-recaptcha > * {
  float: right;
  right: 0;
  margin: -2px -2px -10px;
}

.g-recaptcha::after{
  display: block;
  content: "";
  position: absolute;
  left:0;
  right:150px;
  top: 0;
  bottom:0;
  background-color: #f9f9f9;
  clear: both;
}*/




.rdw-editor-wrapper {
  min-height: 180px !important;
}

.ReactModal__Overlay {
  z-index: 10001 !important;
}

.react-datepicker-wrapper {
  .form-control {
    width: 405% !important;
  }
}
.form-control{
  &:-webkit-autofill,&:-webkit-autofill:active,&:-webkit-autofill:focus,&:-webkit-autofill:hover{
    -webkit-box-shadow: 0 0 0 30px #2e3648 inset !important;
    -webkit-text-fill-color: $gray-500 !important;
  }
  &::placeholder{
    color: $gray-500;
  }
}
.dropzone {
  h3 {
    text-align: center !important;
    padding-top: 100px !important;
  }
  div {
    min-height: 200px !important;
  }
  min-height: 200px !important;
}

input[type='radio'] + label {
  cursor: pointer !important;
}

.topnav .navbar-nav .nav-item .nav-link.active {
  color: $primary !important;
}
.input-color {
  color: $input-color !important;
}
.rangeslider__fill {
  background-color: $primary !important;
}
.rating-container {
  background-color: transparent !important;
}

input[type='checkbox'] {
  margin: 0;
  -webkit-appearance: none;
  background-color: $gray-600;
  border: 1px solid $gray-600;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 0.4rem;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  outline: transparent;
}
input[type='checkbox']:active,
input[type='checkbox']:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

input[type='checkbox']:checked {
  background-color: $blue;
  border: 1px solid $blue;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}
input[type='checkbox']:active,
input[type='checkbox']:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

input[type='checkbox']:checked {
  background-color: $blue;
  border: 1px solid $blue;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}
input[type='checkbox']:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: -3px;
  left: 1px;
  color: $white;
}

.invalid {
  border-color: $red !important;
}
.form-group .invalid-select {
  border: 1px solid $red !important;
  border-radius: 0.25rem;
}
.form-group .error {
  font-size: 12px;
}

.alerts {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 99999999999999;
}

.align-center {
  display: flex;
  align-items: center;
}
.hidden {
  display: none;
}

.table th,
.table td {
  padding: 0.25rem;
}
.table tr:hover,
.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: $header-bg;
}

.sweet-alert {
  background-color: $gray-200!important;
  h2{
    font-size: 1.4em!important;
  }
}
.sweet_alert_with_select{
    p{
        z-index: 0!important;
    }
}

.page-item{
  cursor: pointer;
  &.disabled{
    cursor: default;
  }
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: $gray-300;
  outline: 1px solid slategrey;
}

label{
  margin-bottom: 0;
}
.align-center{
  display: flex;
  align-items: center;
}
.show-on-mobile{
  display: none!important;
}
.telegram-icon-modal{
  font-size: 100px;
}
@media(max-width: 767px){
  body{
    font-size: 12px;
  }
  .page-content{
    padding: 70px 5px 60px 5px;
  }
  .navbar-brand-box{
    padding-right: 0!important;
  }
  hr{
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .telegram-icon-modal{
    font-size: 70px;
  }
  .show-on-mobile{
    display: block!important;
  }
  .hide-on-mobile{
    display: none!important;
  }
  hr{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.update_pool_radio_buttons {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  label {
    margin: 0;
    margin-left: 0.5rem;
    cursor: pointer;
    pointer-events: auto;
    color: $gray-500;
  }
  input[type='radio']:checked + label {
    color: $white;
    cursor: default;
  }
}

.help-list{
  position: fixed;
  width: 100%;
  z-index: 5;
  background-color: $gray-300;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  .help-list-item{
    padding: 5px;
    transition: background-color .3s;
    &:hover{
      background-color: $gray-600;
    }
  }
}