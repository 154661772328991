.mining_preset__create {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mining_preset__filters {
  margin-top: 15px;
  margin-bottom: 20px;
}

.mining_preset__filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  a {
    cursor: pointer;
    margin-left: 15px;
    color: $gray-500 !important;
    &.active {
      color: $white !important;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}

.slot_description {
  font-size: 0.9em;
}

.mining_preset__checkbox {
  margin-left: 0.75rem !important;
}

.mining_preset__tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
  a {
    &:first-child {
      margin-left: 0.75rem;
    }
    color: $gray-500 !important;
    margin-left: 5px;
    background-color: $header-bg;
    padding: 0.25rem 0.75rem;
    font-size: 12px;
    transition: background-color 0.3s;
    cursor: pointer;
    border-radius: 3px;
    &:hover {
      background-color: $gray-0;
    }
    &.active {
      color: $white !important;
      background-color: $blue;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    a {
      margin-bottom: 5px;
    }
  }
}
.mining_preset__table {
  .tableCheckbox {
    width: 40px;
  }
  @media (max-width: 768px) {
    th:last-child {
      display: none;
    }
    th {
      min-width: 40px;
    }
    .actions__items {
      flex-direction: row;
    }
    tr {
      display: flex;
      flex-wrap: wrap;

      position: relative;
      min-height: 35px;
      &:not(:last-child) {
        border-bottom: 1px solid $gray-500;
      }
      td {
        border: none;
      }
      td:last-child {
        margin-left: auto;
        width: auto;
      }
    }
  }
  @media (max-width: 500px) {
    tr {
      min-height: 65px;
    }
    .actions__items {
      flex-direction: column;
    }
  }
}

.mining_preset__action_item {
  margin: 2px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #32394e;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: $black;
  }
}

.mining_preset__create_miner {
  position: absolute;
  right: 1rem;
  z-index: 5;
}

.mining_preset__row_actions {
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin: 3px;
    cursor: pointer;
    padding: 0.5rem;
    transition: all 0.3s;
    border-radius: 3px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      color: $black;
    }
  }
}

.mining_preset__row {
  padding-left: 16px;
  position: relative;
}

.mining_preset__row_number {
  position: absolute;
  z-index: 5;
  top: 12px;
  left: 6px;
  padding: 0.1rem 0.4rem;
  font-size: 10px;
  background-color: $gray-300;
}

.special_select {
  background-image: linear-gradient(135deg, $gray-500 50%, transparent 50%),
    linear-gradient(45deg, transparent 50%, $gray-500 50%);
  background-position: calc(100% - 40px) 1.4em, calc(100% - 45px) 1.4em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.special_select__add {
  position: absolute;
  top: 0em;
  right: 0.8em;
  font-size: 2em;
  color: $gray-500;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: $white;
  }
}

/* Tooltip text */
.special_select__add .special_select__tooltip_text {
  visibility: hidden;
  width: 80px;
  background-color: rgba(0, 0, 0, 0.6);
  color: $gray-500;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 97%;
  left: 50%;
  margin-left: -40px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
  z-index: 20;
}

/* Tooltip arrow */
.special_select__add .special_select__tooltip_text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.special_select__add:hover .special_select__tooltip_text {
  visibility: visible;
  opacity: 1;
}
.special_select__not_add {
  background-position: calc(100% - 4px) 1.4em, calc(100% - 9px) 1.4em;
}

.mining_preset__settings_area {
  border: 1px solid $gray-500;
  background-color: $gray-0;
  padding: 1rem;
}

.mining_preset__add_wallet,
.mining_preset__configure_pool,
.mining_preset__configure_miner {
  transition: all 0.7s;
  overflow: hidden;
  position: relative;
}
.mining_preset__configure_pool,
.mining_preset__configure_miner > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mining_preset__configure_miner {
  height: 0;
  padding-top: 0;
  &.active {
    height: 350px;
    padding-top: 1.5rem;
  }
}
.mining_preset__add_wallet {
  height: 0;
  overflow: hidden;
  &.active {
    height: 190px;
    overflow: visible;
  }
}
.mining_preset__configure_pool {
  height: 0;
  &.active {
    height: 350px;
  }
}

.checkboxLabel {
  /*color: $gray-500!important;*/
  pointer-events: auto !important;
  display: flex !important;
  align-items: center;
  // margin: 0 !important;
  margin-bottom: 3px !important;
  input[type='checkbox'] {
    margin-right: 5px !important;
    border: 1px solid $gray-500;
  }
}

.mining_preset__configure_pool {
  .form-group label {
    background-color: $gray-300;
  }
}

.mining_preset_button {
  width: 100%;
  text-align: left;
  position: relative;
  h6 {
    font-size: 16px;
  }
}

.mining_preset_selected {
  overflow: hidden;
  margin-top: 10px;
  height: 0px;
  &.selected {
    height: 70px;
    transition: height 0.4s;
    @media (max-width: 768px) {
      height: 90px;
    }
  }
  .mining_preset_selected__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-left: 5px;
    }
  }
}

.mining_preset_options {
  .quicksearch {
    max-width: unset;
    width: 100%;
    display: inline-block;
    float: none;
    margin-right: 0;
    position: relative;
    margin-bottom: 15px;
    .form-control {
      padding: 0.47rem 0.75rem;
    }
  }
  .mining_presets_list {
    padding: 5px;
    min-height: 170px;
    max-height: 350px;
    overflow-y: auto;
    transition: height 0.4s;
  }
}
.mining_preset_info {
  font-size: 12px;
  color: $gray-500;
  img {
    width: 16px;
    margin-right: 5px;
  }
  i {
    margin-top: 5px;
    font-size: 14px;
    margin-right: 3px;
    color: #fff;
  }
}
.mining_preset_used {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  border-radius: 100%;
  background-color: $gray-100;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.mining_preset_used_star {
  right: 2rem;
}
.mining_preset__configure_miner {
  @media (max-width: 768px) {
    overflow-y: auto;
  }
}

.mining_preset__configure_miner .form-group {
  margin-bottom: 15px;
}

.mining_preset__body {
  width: 100%;
  .dropdown {
    position: static !important;
  }
  .dropdown-menu {
    top: 0 !important;
  }
}

.miner_dual_mode {
  position: absolute;
  right: 0;
  top: 0;
}

.poolServersList {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}
.poolServersList .switch-group span span {
  display: inline-block;
  min-width: 100px;
  text-transform: lowercase;
  color: inherit;
  text-align: center;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .mining_preset__settings_area {
    padding: 0.5rem;
  }
  .mining_preset__configure_miner {
    height: 0;
    padding-top: 0;
    padding-right: 0.5rem;
    .miner_dual_mode {
      right: 1rem;
    }
    &.active {
      height: 38vh;
      padding-top: 1.5rem;
    }
  }
  .mining_preset__add_wallet {
    height: 0;
    overflow: hidden;
    &.active {
      height: 190px;
      overflow: visible;
    }
  }
  .mining_preset__configure_pool {
    height: 0;
    &.active {
      height: 40vh;
    }
    .poolServersList {
      max-height: 17vh;
    }
  }
  .mining_preset_options {
    .mining_presets_list {
      max-height: 60vh;
      font-size: 12px;
    }
  }
  .mining_preset_info {
    font-size: 12px;
  }
  .mining_preset_button {
    h6 {
      font-size: 14px;
    }
  }
}
