.power_unit__create{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}


.account__body{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.account{
    width: 60%;
    padding-right: 1rem;
    border-right: 1px solid $gray-500;
    h5{
        margin-bottom: 20px;
    }
}


.account_password_description{
    font-size: 12px;
    text-align: left;
    margin: 20px 0;
}

.account__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .account__row__field{
        display: flex;
        flex-direction: column;
    }
    margin-bottom: 10px;
    position: relative;
    .error{
        text-align: center;
        font-size: 12px;
    }
    input,select{
        max-width: 200px;
        padding: 0.5rem!important;
        margin: 0!important;
    }
    label{
        max-width: 200px;
        padding: 0!important;
        margin: 0!important;
    }
    h5{
        margin: 0;
    }
    .form_control_react_select__control{
        width: 200px;
        padding: 0!important;
        height: 2rem!important;
    }
    .form_control_react_select__indicators{
        margin: 0!important;
    }
    .form_control_react_select__value-container{
        height: 100%!important;
    }
}

.account__row_center{
    justify-content: center;
}



.account_pass{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
    padding-left: 1rem;
    .account_pass_header{
        width: 100%;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .modal-footer{
        margin-top: auto;
        position: relative;
        top: 100%;
        bottom: 0;
    }
    h5{
        margin-bottom: 20px;
    }
}

.account_pass__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    input,select,label{
        max-width: 200px;
        padding: 0.5rem!important;
        margin: 0!important;
    }
    h5{
        margin: 0;
    }
}

.lastlogin_table{
    th:last-child,td:last-child{
        text-align: right;
    }
}

.session{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child){
        padding-bottom: 3px;
        border-bottom: 1px solid $gray-500;
        margin-bottom: 7px;
    }
}

.session__data{
    font-size: 0.9em;
}

.session__status{
    font-size: 1.1em;
    color: $gray-1000;
}
.session__status_active{
    color: $green;
    font-weight: 700;
}

.account__add{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.account__fifty{
    .account,.account_pass{
        width: 50%;
    }
}