.power_unit__create{
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}


.powerUnit__body{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.powerUnit__add{
  width: 60%;
  padding-right: 1rem;
  border-right: 1px solid $gray-500;
  @media (max-width: 991px) {
    width: 100%;
    border: none;
  }
}


.powerUnit__add__row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  input,select,label{
    max-width: 200px;
    padding: 0.5rem!important;
    margin: 0!important;
  }
  h5{
    margin: 0;
  }
  .form_control_react_select__control{
    width: 200px;
    padding: 0!important;
    height: 2rem!important;
  }
  .form_control_react_select__indicators{
    margin: 0!important;
  }
  .form_control_react_select__value-container{
    height: 100%!important;
  }
}

.powerUnit__add__row_center{
  justify-content: center;
}


.powerUnit__status{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
  padding-left: 1rem;
  .powerUnit__status_header{
    border-bottom: 1px dashed $gray-500;
    width: 100%;
    text-align: center;
  }
  .modal-footer{
    margin-top: auto;
    position: relative;
    top: 100%;
    bottom: 0;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
}

.powerUnit__status__row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  input,select,label{
    max-width: 200px;
    padding: 0.5rem!important;
    margin: 0!important;
  }
  h5{
    margin: 0;
  }
}
.powerUnit__status__row_center{
  justify-content: center;
}
.powerUnit__status_text{
  padding: 0.5rem!important;
  height: auto;
}

.powerUnit__status_answer{
  font-size: 1.25rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin: 20px auto;
}


.outletRow {
  width: 100%;
  align-items: center;
  margin-bottom: 3px;
}
.outlets{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.powerUnit__body .form-control{
  padding: 0.47rem 0.75rem;
}

.powerUnit__body .form_control_react_select__control{
  padding: 0!important;
}

.powerUnit__table{
  td {
    &:first-child {
      width: 40px;
    }
  }
  @media (max-width: 768px){
    th:last-child{
      display: none;
    }
    th{
      min-width: 40px;
    }
    tr{
      display: flex;
      flex-wrap: wrap;
      position: relative;
      min-height: 120px;
      padding-right: 30px;
      &:not(:last-child){
        border-bottom: 1px solid $gray-500;
      }
      td{
        border: none;
      }
      td:last-child{
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
      }
    }
  }

}