.popup {
  .modal-header {
    position: relative;
    justify-content: center;
    .close {
      position: absolute;
      right: 1rem;
    }
  }
  /*position: relative;
  width: 90%;
  max-width: 500px;
  min-height: 200px;
  padding: 1rem 1rem 0;
  background-color: $card-bg;
  border-radius: 5px;*/
  h4 {
    text-align: center;
    margin-bottom: 15px;
  }
}

.popup_large.modal-dialog {
  max-width: 950px;
}

.popup_large-x.modal-dialog {
  max-width: 980px;
}

.popup.createWorker {
  form .form-group label {
    z-index: 5;
    position: relative;
    font-size: 0.8rem;
    margin-bottom: -20px;
    display: block;
    margin-left: 5px;
    color: $gray-500;
    pointer-events: none;
  }
  .form-group .form-control {
    padding: 1rem 0.75rem 0.3rem;
    height: auto;
  }
}
.popup.card_turbo {
  form .form-group label {
    z-index: 5;
    position: relative;
    font-size: 0.7rem;
    margin-bottom: 0;
    display: block;
    margin-left: 5px;
    color: $gray-1100;
    pointer-events: none;
  }
  .form-group .form-control {
    padding: 0.4rem 0.75rem;
    height: auto;
  }
}

.wallet-checkbox {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  color: #9ca8b3;
  white-space: nowrap;
}
