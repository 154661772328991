//
// preloader.scss
//

// .preloader {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba($white, 0.7);
//     z-index: 9999;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

// .status {
//     width: 40px;
//     height: 40px;
//     position: relative;
// }

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($gray-100, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader_total {
  width: 100%;
  position: absolute;
  height: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.console_preloader {
  position: sticky;
  top: 45%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  width: 150px;
  height: 15px;
  position: relative;
  margin: auto;
}
