.settings__watchdog{
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0;
    .settings__row{
      flex-wrap: wrap;
      padding-bottom: 5px;
      border-bottom: 1px solid $gray-500;
    }
  }
}

.settings__section{
  padding: 10px;
}

.settings__section_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray-500;
  margin-bottom: 10px;
}

.settings__row{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  .form-control[type='text']{
    width: 50px;
    margin: 0 7px;
    padding: 0.47rem 0.25rem;
    height: 2rem;
  }
  .form_control_react_select__control{
    padding: 0!important;
    width: 150px;
    margin-left: 7px;
    height: 2rem;
  }
  .form_control_react_select__indicators{
    margin: 0!important;
  }
  .form_control_react_select__value-container{
    height: 100%;
  }
}

.settings__radiogroup{
  margin-left: 7px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.settings__section_body{
  transition: opacity 0.4s;
}







.settings__general{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  input[type='text']{
    margin-right: 0!important;
  }

  &>div{
    width: 50%;
  }
  .settings__row{
    justify-content: space-between;
  }
  hr{
    border-color: $gray-500;
    margin: 1rem 2rem 1rem -1rem;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    &>div{
      width: 100%;
    }
  }
}


.settings__dashed{
  border: 1px dashed $gray-500;
  padding: 0.5rem 0.5rem 0 0.5rem;
  margin-bottom: 0.5rem;
}
